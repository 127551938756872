button,
.product-card-layover {
  border: none !important;
}

.product-modal {
  height: auto;
  .modal-add {
    margin-top: 64px;
    background-color: #5a94fb;
    padding: 8px 16px;
    border-radius: 24px;
    color: white;
    font-family: "Montserrat";
    font-weight: 600;
  }
  .modal-body {
    justify-content: flex-start;
    height: auto !important;
  }
  img {
    height: 300px;
  }
}

.product-card,
.product-card-layover {
  .pres {
    color: rgb(255, 66, 66) !important;
  }
  background-color: #edf3ff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 0.1px solid rgba(85, 84, 84, 0.5);
  padding: 12px 0px;
  .cart-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
    z-index: 999;
    filter: drop-shadow(1px 2px 8px hsl(220deg 100% 55%));
  }

  .text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
  }
  .name {
    text-align: left;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    margin: 0px;
    font-size: 17px;
    line-height: 150%;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #1a202c;
    // flex: 2;
  }
  .desc {
    text-align: left;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    margin: 0px;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #4a5468;
  }
  .price {
    margin-top: 4px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #2b63c7;
  }
  .right {
    display: flex;
    gap: 16px;
  }

  .image {
    object-fit: contain;
    width: 90px;
    height: 90px;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    // justify-self: flex-end;
    margin-right: 8px;
  }
}

.add {
  cursor: pointer;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  // width: 59px;
  height: 33px;
  background: #5a94fb;
  border-radius: 4px;
  border: none;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;

  color: #ffffff;
}
