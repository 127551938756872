.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #2b63c7;
  border-color: #2b63c7 transparent #2b63c7 transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.pharmacy-card-list {
  background-color: #edf3ff;
  padding-bottom: 80px;

  .head {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
    margin-top: -12px;
    @media only screen and (max-width: 700px) {
      flex-direction: column !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .head {
      flex-direction: column !important;
    }
  }

  .promo {
    // background-color: white !important;
    .header {
      background-color: transparent;
      font-family: "Cairo";
      font-style: normal;
      font-weight: 600;
      font-size: 38px;
      line-height: 150%;
      /* or 57px */

      text-align: center;
      letter-spacing: -0.01em;

      /* Neutrals/900 */

      color: #1a202c;
    }

    .sub {
      margin: auto;
      width: 321px;
      font-family: "Cairo";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      /* or 27px */

      text-align: center;
      letter-spacing: -0.01em;

      /* Neutrals/700 */

      color: #4a5468;
    }
  }
  .header {
    position: relative;
  }

  .phone-icon {
    position: absolute;
    // top: 10px;
    right: 24px;
    cursor: pointer;
    font-size: 24px; /* Adjust the font size as needed */
    color: #fff; /* Adjust the color as needed */
  }
  .header {
    background-color: #2b63c7;
    height: 106px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    padding-left: 16px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    // width: 342px;
    height: 46px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .categories {
    display: flex;
    justify-content: space-between;
    button {
      height: 27px;
      border: none;
      background-color: transparent;

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.01em;
      font-feature-settings: "calt" off;

      color: #505050;
    }
    button:focus,
    button:active {
      border-bottom: 2px solid #2b63c7;
    }
  }
  .title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
  }

  .cat-cards {
    width: 85%;
    margin: auto;
    display: flex;
    gap: 8px;
    overflow-x: scroll;
    img {
      height: 120px;
      width: 120px;
    }
  }
  .her {
    img {
      height: 120px;
    }
  }
  .logo {
    height: 50px;
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    gap: 16px;
  }
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;

  color: #2d3648;
}
.sub {
  font-weight: 700;
  font-size: 14px;
}
