a {
  text-decoration: none !important;
}
.pharmacy-card {
  position: relative;
  background: #ffffff;
  border: 0.1px solid rgba(85, 84, 84, 0.25);
  box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 205px;
  width: 342px;
  .card-header {
    height: 132px;
    width: 342px;
    border-radius: 8px 8px 0 0 !important;
    background-size: cover;
  }
  .card-body {
    // position: relative;

    .city {
      position: absolute;
      left: 250px;
      top: 170px;
    }
    .card-image {
      img {
        border-radius: 8px;
        // object-fit: cover;
      }
      position: absolute;
      left: 245px;
      top: 84px;
      width: 80px;
      height: 80px;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
      border-radius: 80px;
    }
    .card-title {
      margin-top: 8px;
      text-decoration: none !important;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      // line-height: 19.5px;
      font-feature-settings: "calt" off;
      color: #1a202c;
      text-align: left;
    }
    .card-description {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      font-feature-settings: "calt" off;
      color: #4a5468;
      text-align: left;
    }
  }
}
