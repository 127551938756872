.cart-page {
  background-color: #edf3ff;
  .cart {
    display: flex;
    flex-direction: column;
    .item {
      justify-content: space-around;
      display: flex;
    }
  }
  .remove-btn {
    border: none;
    background-color: transparent;
    // height: 30px;
    margin-left: 4px;
  }

  .cart-btn {
    cursor: pointer;
    border: none;
    position: fixed;
    width: 77vw;
    height: 56px;
    bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 17px;
    gap: 10px;
    background: #2b63c7;
    border-radius: 8px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #ffffff;
  }

  .cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
  }

  .cart-item-img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 1rem;
  }

  .cart-item-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .cart-item-name {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .cart-item-price {
    font-size: 1rem;
  }

  .cart-item-quantity {
    font-size: 1rem;
    margin-left: auto;
  }
}
