.products-page {
  .pres {
    background-color: #2b63c7;
    padding: 16px 0px;
    .pres-btn {
      color: white;
    }
  }
  background-color: #edf3ff;
  padding-bottom: 160px;

  height: auto !important;
  input {
    padding-left: 16px !important;
    background: #ffffff;
    /* Card subtitle */

    border: 1px solid #8c8c8c;
    border-radius: 5px;
    width: 100%;
    height: 43px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .modal {
    filter: drop-shadow(0px -49px 50px rgba(43, 99, 199, 0.3));
    background-color: #edf3ff !important;
    border-top: 1px solid grey;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    // overflow-y: scroll;
    // background-color: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-bottom: 100px;
    animation: slide-up 0.5s ease;
  }
  .modal-content {
    overflow-y: scroll;
    background-color: #edf3ff;
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  .cart-close-btn {
    align-self: flex-end;
    margin-top: auto;
    margin-right: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
  }

  .cart-btn {
    cursor: pointer;
    border: none;
    position: fixed;
    width: 90vw;
    height: 56px;
    bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 17px;
    gap: 10px;
    background: #2b63c7;
    border-radius: 8px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #ffffff;
  }
  .pharmacy-header {
    height: 177px;
  }

  .cats {
    margin-top: 16px;

    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 16px;
  }
  .pharmacy-header-card {
    box-sizing: border-box;
    margin: auto;
    margin-top: -60px;
    padding: 16px 16px;
    width: 357px;
    height: 168px;
    background: #ffffff;
    border: 0.2px solid #000000;
    border-radius: 16px;

    .card-body {
      // gap: 24px;
      width: 95%;
      margin: auto;
      position: relative;
      display: flex;
      gap: 16px;
      .texts {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
      }
      .card-image {
        border-radius: 100px !important;
        img {
        }
        width: 80px;
        height: 80px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
      }
      .card-title {
        text-decoration: none !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        margin: 0px;
        font-feature-settings: "calt" off;
        color: #1a202c;
        text-align: left;
      }
      .card-description {
        margin: 0px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 150%;
        font-feature-settings: "calt" off;
        color: #4a5468;
        text-align: left;
      }
    }
    .card-pres {
      margin: 0px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 150%;
      font-feature-settings: "calt" off;
      color: #4a5468;
      // text-align: left;
      margin-left: 160px;
      margin-top: -8px;
    }
    .-btn {
      background-color: transparent;
    }
    .pres-btn {
      margin-left: 160px;
      background-color: #2b63c7;
      border: none;
      border-radius: 24px;
      padding: 4px 8px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: white;
    }
  }

  input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    // width: 342px;
    height: 46px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .categories {
    display: flex;
    justify-content: space-between;
    button {
      height: 27px;
      border: none;
      background-color: transparent;

      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.01em;
      font-feature-settings: "calt" off;

      color: #505050;
    }
    button:focus,
    button:active {
      border-bottom: 2px solid #2b63c7;
    }
  }
}
