.modal {
  margin-top: 40px;
}
.map-container {
  margin-top: 24px;
  height: 400px;
}
.modal-body {
  position: absolute;
  height: auto !important;
  .confirm {
    position: relative;
    cursor: pointer;
    border: none;
    // position: fixed;
    margin-top: 80px;
    width: 90%;
    height: 56px;
    bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 17px;
    gap: 10px;
    background: #2b63c7;
    border-radius: 8px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #ffffff;
  }
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 800px;
}
.checkout-page {
  background-color: #edf3ff;
  // height: 150vh;
  padding-bottom: 120px;
  #pres {
    background-color: transparent !important;
    border: none;
  }
  .items {
    margin-top: 32px;
  }
  .col {
    text-align: left;
  }
  .item {
    display: flex;
    justify-content: space-between;
  }

  padding-top: 24px;
  .title {
    // line-height: 12px;
  }
  .title-bar {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    button {
      border: none;
      background-color: transparent;
    }
  }

  .next-btn {
    position: relative;
    cursor: pointer;
    border: none;
    position: fixed;
    width: 90%;
    margin: auto;
    height: 56px;
    bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 17px;
    gap: 10px;
    background: #2b63c7 !important;
    border-radius: 8px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.01em;
    font-feature-settings: "calt" off;
    color: #ffffff;
  }
}
.checkout-cart {
  .modal-footer {
    display: flex;
  }
  .submit-btn {
    width: auto;
    border: none;
    background-color: #2b63c7;
    color: white;
    font-weight: 600;
  }
  label {
    margin-top: 8px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #52535f;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  input,
  select {
    background: #ffffff;
    border: 1px solid #8c8c8c;
    border-radius: 5px;
    width: 100%;
    height: 43px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .small-check {
    width: 17px !important;
    height: 17px !important;
    margin-top: 24px !important;
    margin-right: 12px;
    margin-left: 12px;
    background: #c6c6c6;
    border-radius: 4px;
  }
}
