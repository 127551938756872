.product-card-list {
  background-color: #edf3ff;
  margin-top: 16px;
  // height: 100vh;

  .temp {
    display: flex;
    align-items: center;
    position: sticky;
    top: 40px;
  }

  .bar {
    display: flex;
    justify-content: flex-start;
  }
  .back-button {
    background-color: transparent;
  }

  .category-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
    // width: auto;
    button {
      width: 146px !important;
      height: 104px !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 31px 13px;
      gap: 10px;
      width: 146px !important;
      height: 104px !important;

      background-color: #fff !important;
      box-shadow: 2px 2px 14px -2px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
    }
    .cat-box {
      .cat-text {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        /* identical to box height, or 24px */

        text-align: center;
        letter-spacing: -0.01em;
        font-feature-settings: "calt" off;

        // color: #505050;
        color: #2b63c7;
      }
    }

    // .category-bar::-webkit-scrollbar {
    //   display: none !important;
    // }
    // ::-webkit-scrollbar {
    //   display: none !important;
    // }
    gap: 24px;
    button {
      height: 27px;
      border: none;
      background-color: transparent;
      margin: 8px 0px;

      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      letter-spacing: -0.01em;
      font-feature-settings: "calt" off;

      color: #505050;
    }
    button:focus,
    button:active {
      border-bottom: 2px solid #2b63c7;
    }
  }
  .cat {
    width: 90%;
    margin: auto;
    .category {
      // position: sticky;
      // top: 40px;
      padding: 4px 8px;
      margin: 16px 0 0 0;
      border-radius: 8px;
      background-color: white;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 150%;

      text-align: left;

      letter-spacing: -0.01em;
      font-feature-settings: "calt" off;

      color: #000000;
    }
  }
}
